@charset 'UTF-8';

// 1. Configuration and helpers
@import 'abstracts/variables',
'abstracts/functions',
'abstracts/mixins';

// .content-container {
//   &.expand {
//     .site-group-list-container {
//       left:27.1rem;
//     }
//   }
// }

// .site-group-list-container {
//   position:fixed;
//   top:5.6rem;
//   left:8rem;
//   bottom:0;
//   width:30.6rem;
//   box-sizing: content-box;
//   transition:left .25s;

//   .header {
//     display: none;
//     position:absolute;
//     top:0;
//     left:0;
//     z-index: 100;
//     width:100%;
//     height: 5.2rem;
//     background-color:$color-white;

//     .btn-back {
//       overflow:hidden;
//       width:5.4rem;
//       height:5.4rem;
//       border-radius: 0;
//       border:none;
//       background:asset-image('btn_back.svg') center no-repeat;
//       text-indent: -9999px;
//     }

//     .title {
//       @include set-font(2.1rem, bold, $color-gray-01);
//     }
//   }

//   .child-menu-container {
//     position: static;
//     padding-bottom:6rem;

//     .bottom-menu {
//       height:6rem;
//       background-color: $color-white;
//     }
//   }
// }


// CC 2.0 추가
.site-group-list-container {
  position: absolute;
  top: 0rem;
  left: 3.2rem;
  bottom: 0;
  width: 32rem;
  // min-height: 74rem;
  box-sizing: content-box;
  border: 1px solid $color-border-03 !important;
  border-radius: 1rem !important;
  transition: left .25s;
  background-color: $color-white;
  z-index: 99;
  box-sizing: border-box;
  .header {
    display: none;
    width:100%;
    height: 6rem;

    .btn-back {
      position: absolute;
      top:.8rem;
      left:1.2rem;
      width:4.4rem;
      height:4.4rem;
      border-radius: 0;
      border:none;
      background:asset-image('ic_back.svg') center no-repeat;
      text-indent: -9999px;
    }

    .title {
      padding: 1.4rem 1.4rem 1.4rem 6.8rem;
      @include set-font(2.4rem, 700, $color-black, 130%);
    }
  }
  .child-menu-container {
    padding: 1rem;
    .pecialized-group {
      margin-bottom: 0.6rem;
      .dropdown-icon {
        padding: 1.6rem;
        border: 1px solid #EEF2F9;
        border-radius: 0.8rem;
        background-color: #f8fafd;
        .btn-icon_group {
          @include set-font(1.6rem, 400, #333, normal);
          width: 100%;
          background: asset-image('ic_arrow_down.svg') no-repeat 100% center / 1.8rem;
          &.active {
            background: asset-image('ic_arrow_up.svg') no-repeat 100% center / 1.8rem;
            &+.dropdown-icon-list {
              display: block;
            }
          }
        }
        .dropdown-icon-list {
          display: none;
          margin-top: 2rem;
          .lists {
            font-size: 0;
            .list {
              display: flex;
              flex-wrap: wrap;
              .btn-icon {
                padding: 0;
                width: 8.05rem;
                height: 3.4rem;
                border: 1px solid #DADDE2;
                border-radius: 0.6rem;
                vertical-align: top;

                &:nth-child(n+4) {
                  margin-top: 0.8rem;
                }
                &:nth-child(4) {
                  margin-left: 0 !important;
                }
                &.btn-monitoring {
                  background: #fff asset-image('ic_monitoring_setting.svg') no-repeat center / 2.2rem;
                }
                &.btn-update {
                  background: #fff asset-image('ic_auto_update.svg') no-repeat center / 2.2rem;
                }
                &.btn-cloning {
                  background: #fff asset-image('ic_auto_cloning.svg') no-repeat center / 2.2rem;
                }
                &.btn-uniController {
                  background: #fff asset-image('ic_filter.svg') no-repeat center / 2.2rem;
                }
                &.btn-energy {
                  background: #fff asset-image('ic_energy_saving.svg') no-repeat center / 2.2rem;
                }
                &.btn-schedule {
                  background: #fff asset-image('ic_rebootschedule.svg') no-repeat center / 2.2rem;
                }
                &+.btn-icon {
                  margin-left: 0.8rem;
                }
                &:hover {
                  background-color: #f5f5f5;
                }
                &.disabled-type {
                  opacity: 0.6;
                  pointer-events: none;
                }
              }
              &+.list {
                margin-top: 0.8rem;
              }
            }
          }
        }
      }
    }

    .devices-menu-site {
      margin-top: .6rem;
      height: calc(100% - 7rem);
      
      .title {
        @include set-font(1.4rem, 700, #697387, 1.4);
        padding: 1.8rem 1.6rem .6rem 1.6rem;
      }
      .inner-title {
        @include set-font(1.6rem, 400, #333, 1.4);
        position: relative;
        padding: 1rem 1.6rem 1rem 3rem;
        .btn-setting {
          position: absolute;
          top: 1rem;
          right: 1.6rem;
          padding: 0rem;
          width: 2.2rem;
          height: 2.2rem;
          background: #fff asset-image('ic_settings.svg') no-repeat center / 2.2rem;
        }
      }
      ul {
        li {
          &.child-menu {
            button {
              display: block;
              padding-left: 3rem;
              border-radius: .8rem;
              width: 100%;
              text-align: left;
              > span {
                @include set-font(1.6rem, 400, #333, 1.4);
                display: block;
                padding: 1rem 1.6rem 1rem 3rem;
                min-height: 4.4rem;
                background: asset-image('ic_group.svg') no-repeat .5rem 1rem / 2.2rem;
                position: relative;
                word-break: break-word;

                .child-menu-num {
                  position: absolute;
                  right: 1.6rem;
                  @include set-font(1.4rem, 400, #555555);
                }
              }
              &:hover {
                background-color: #E8EBF0 !important;
              }
              &.active {
                background-color: #E8EBF0 !important;

                span {
                  font-weight: 700;
                }
              }
            }
            &.has-child {
              & > button {
                background: asset-image('ic_arrow_down.svg') no-repeat 1rem 1rem / 2.2rem;
              }
            }
            &.open-menu {
              .child-menu {
                display: block;
              }
              & > button {
                background: asset-image('ic_arrow_up.svg') no-repeat 1rem 1rem / 2.2rem;
              }
            }
            .child-menu {
              display: none;
              button {
                padding-left: 6rem;
                background-position: 4rem 1rem;
              }
              .child-menu {
                button {
                  padding-left: 9rem;
                  background-position: 7rem 1rem;
                }
              }
            }
            &.disabled {
              display: block;
              // padding-left: 3rem;
              border-radius: .8rem;
              opacity: 0.7;
              span {
                @include set-font(1.6rem, 400, #333, 1.4);
                display: block;
                padding: 1rem 1.6rem 1rem 3rem;
                min-height: 4.4rem;
                background: asset-image('ic_blank.svg') no-repeat .5rem 1rem / 2.2rem;
              }

              button {
                &:hover {
                  background: #fff !important;
                  cursor: default;
                }
              }
            }
          }

          &.create-group {
            button {
              background-color: #F5F6FA;
              padding: 0 1.6rem;
              height: 4.8rem;

              span {
                min-height: 4.8rem;
                background: asset-image('ic_add.svg') no-repeat 0 1rem / 2.4rem;
                color: #757575;
              }
            }
          }
        }
      }
    }

    .category-menu {
      ul {
        li {
          @include set-flex(row, flex-start, center);
          position: relative;
          border-radius: .8rem;
    
          &:before {
            content:'';
            display: none;
            position: absolute;
            top: 50%;
            left: 1rem;
            transform: translateY(-50%);
            width: 1.2rem;
            height: 1.2rem;
            background: asset-image('sel_icon.svg') no-repeat 1px 2px;
          }
    
          &:hover {
            background-color: #E8EBF0;
          }
    
          &.active {
            background-color: #E8EBF0;
            // outline: 1px solid $color-primary;
            
    
            &:before {
              display: none;
            }
    
            a {
              font-weight: 500;
              text-decoration: underline;
            }
          }
    
          a {
            @include set-flex(row, flex-start, center);
            padding: 0 0 0 3rem;
            width: 100%;
            height: 4.7rem;
            text-transform: capitalize;
            @include set-font(1.6rem, 400, $color-gray-01);
          }
        }
      }
    }
  }
}


@media screen and (max-width:1280px) {
  .site-group-list-container {
    position: fixed;
    left:-100%;
    // width:100%;
    // border: 0;
    border-radius: 0;
    z-index: 999;

    &.active {
      left:0;
    }

    .header {
      display: block;
    }

    .child-menu-container {
      width:100%;
      // padding-top:5.2rem;

      .bottom-menu {
        max-width:inherit;
      }
    }
  }
}


@media screen and (max-width:640px) {
  .site-group-list-container {
    width:100%;
    border: 0 !important;
    border-radius: 0 !important;
  }
}